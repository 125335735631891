import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "../../styles/media";

const Logo = ({ fill = "#fff" }: { fill?: string }) => {
  return (
    <LogoContainer>
        <svg 
            className="Logo__Desktop"
            height="25px"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 365.77 75.37"
        >
            <g fill={fill} id="Layer_2" data-name="Layer 2">
            <g fill={fill} id="Layer_1-2" data-name="Layer 1">
                <path d="M81.58,56.08c2.47-2.4,3.74-4.85,3.88-7.44.15-2.87-1.13-5.82-3.88-9a11.08,11.08,0,0,0,0-16.71,10.79,10.79,0,0,0,3.21-11.77c-1.58-4.63-5.68-7.39-11-7.39H62.73q-13.95,0-27.88,0a33.33,33.33,0,0,0-15.92,4A36,36,0,0,0,.67,46.57a35,35,0,0,0,34.78,28.8H73.13c.67,0,1.43,0,2.19-.05A11,11,0,0,0,85.32,63,11.07,11.07,0,0,0,81.58,56.08Zm-6-19.51-.18,0H67.69a33.77,33.77,0,0,0-2-10.8l1.61,0c2.53,0,4.92-.06,7.32.06a5.33,5.33,0,0,1,5.28,5,5.46,5.46,0,0,1-4.13,5.69ZM74.4,53.23c-5.32,0-10.65,0-16,0a5.07,5.07,0,0,1-4.74-2.86,4.9,4.9,0,0,1-.22-4.6,9.23,9.23,0,0,0,9-.11,11.5,11.5,0,0,0,2.3-1.88c.34-.33.68-.66,1-1s.53-.38.5-.38c2.86-.06,5.72,0,8.13,0a5.41,5.41,0,1,1,0,10.82Zm-.08-33.1c-3.7.06-7.42,0-11.22,0a.5.5,0,0,1-.16,0,35.39,35.39,0,0,0-8.25-9.25,5.09,5.09,0,0,1,3.75-1.55c5.07,0,10.47-.06,15.94,0a5.42,5.42,0,1,1-.06,10.83ZM57.11,21.42a25.83,25.83,0,0,1,5,15.59A4.27,4.27,0,0,1,58,41.33a4.23,4.23,0,0,1-4.3-4c-.62-8.92-5.18-15.15-13.17-18-7.39-2.62-14.29-1.13-20,4.34-5.83,5.63-7.54,12.67-5,20.38,2.46,7.31,7.78,11.73,15.8,13.11a17.09,17.09,0,0,0,2.27.18,3.09,3.09,0,0,0,2.41-.86,2.6,2.6,0,0,0,.74-2c0-1.07-.57-2.42-2.81-2.74L33,51.65a17.49,17.49,0,0,1-3.65-.72,14,14,0,0,1-8.94-16.18,14.18,14.18,0,0,1,15.18-11c6.72.56,12.14,6.31,12.61,13.39a13.43,13.43,0,0,0,.69,3c.14.45.28.89.39,1.34q0,.18.06.27c-2.89,5.76-2.31,10.11,2,14.26A11.06,11.06,0,0,0,49,69.88H45.79c-3.68,0-7.48,0-11.21-.07A29.5,29.5,0,0,1,6.76,48.3,30.74,30.74,0,0,1,10,23.44,29.22,29.22,0,0,1,30,9.84a30,30,0,0,1,5.26-.48C43.85,9.36,51.17,13.4,57.11,21.42ZM58.45,59l3,0H69c1.81,0,3.62,0,5.43,0a5.44,5.44,0,0,1,4,9.2,5.2,5.2,0,0,1-3.68,1.62c-5.58.11-11.14.11-16.52,0a5.11,5.11,0,0,1-3.64-1.63A5.42,5.42,0,0,1,58.45,59Z"/>
                <path d="M244.83,23a13.81,13.81,0,0,1,11.91,6l7.4-6.76q-6.87-9.22-19.85-9.23-11.7,0-19.21,7.67t-7.51,19.48q0,11.81,7.51,19.53t19.21,7.73q12.89,0,19.85-9.23l-7.4-7a13.87,13.87,0,0,1-11.91,6.23,14.5,14.5,0,0,1-11.32-4.83q-4.35-4.83-4.35-12.45t4.35-12.4A14.59,14.59,0,0,1,244.83,23Z"/>
                <polygon points="320.05 14.35 306.1 14.35 283.25 39.35 283.25 0 271.98 0 271.98 66.18 283.25 66.18 283.25 52.23 290.54 44.72 306.32 66.18 320.48 66.18 298.59 37.85 320.05 14.35"/>
                <path d="M362.55,41.93a16.68,16.68,0,0,0-7.84-5c-3.07-.93-6.15-1.7-9.22-2.31a38.37,38.37,0,0,1-7.84-2.3c-2.14-.93-3.22-2.22-3.22-3.87a5.45,5.45,0,0,1,2.63-4.61A11.87,11.87,0,0,1,344,22q9.66,0,15.35,6.22l4.72-7.94q-8.16-7.18-20.07-7.19-9.44,0-14.91,4.5a13.94,13.94,0,0,0-5.48,11.17,12.66,12.66,0,0,0,3.22,9,17.13,17.13,0,0,0,7.84,4.78q4.61,1.33,9.23,2.25a32.69,32.69,0,0,1,7.83,2.47q3.23,1.55,3.22,4.34a5.82,5.82,0,0,1-2.68,5c-1.79,1.26-4.33,1.88-7.62,1.88a24.55,24.55,0,0,1-9.71-2.09,23.42,23.42,0,0,1-7.78-5.1L322,59.31q8.37,8.16,22.22,8.16,10.19,0,15.88-4.51a14.25,14.25,0,0,0,5.69-11.7A13.55,13.55,0,0,0,362.55,41.93Z"/>
                <path d="M200.35,44.21a12.6,12.6,0,0,1-25.19,0V14.34H164.61V44.21a23.15,23.15,0,1,0,46.29,0V14.34H200.35Z"/>
                <path d="M134.11,14.34A23.14,23.14,0,0,0,111,37.49V67.36h10.55V37.49a12.6,12.6,0,0,1,25.19,0V67.36h10.55V37.49A23.15,23.15,0,0,0,134.11,14.34Z"/>
            </g>
            </g>
        </svg>

        <svg
            className="Logo__Mobile"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            height="25px"
            viewBox="0 0 85.47 71.59"
        >
            <g id="Mark_Layer_2" data-name="Layer 2">
                <g fill={fill} id="Mark_Layer_1-2" data-name="Layer 1">
                    <path d="M81.58,52.29c2.47-2.4,3.74-4.84,3.88-7.43.15-2.88-1.13-5.83-3.88-9a11.18,11.18,0,0,0,3.83-8.36,11.32,11.32,0,0,0-3.78-8.35A10.79,10.79,0,0,0,84.84,7.39C83.26,2.76,79.16,0,73.88,0h-39A33.45,33.45,0,0,0,18.93,4,36,36,0,0,0,.67,42.79,35,35,0,0,0,35.45,71.58H73.13c.67,0,1.43,0,2.19,0a11,11,0,0,0,10-12.35A11.07,11.07,0,0,0,81.58,52.29Zm-6-19.51-.18,0H67.69a33.78,33.78,0,0,0-2-10.81l1.61,0c2.53,0,4.92-.05,7.32.07a5.32,5.32,0,0,1,5.28,5,5.46,5.46,0,0,1-4.13,5.69ZM74.4,49.45c-5.32,0-10.65,0-16,0a5.05,5.05,0,0,1-4.74-2.86,4.9,4.9,0,0,1-.22-4.6,9.23,9.23,0,0,0,9-.11A11.46,11.46,0,0,0,64.76,40c.34-.33.68-.67,1-1s.53-.38.5-.39c2.86-.06,5.72,0,8.13,0a5.41,5.41,0,1,1,0,10.82Zm-.08-33.1c-3.7,0-7.42,0-11.22,0l-.16,0a35.35,35.35,0,0,0-8.25-9.24,5.09,5.09,0,0,1,3.75-1.55c5.07,0,10.47-.06,15.94,0a5.42,5.42,0,1,1-.06,10.84ZM57.11,17.63a25.86,25.86,0,0,1,5,15.6A4.27,4.27,0,0,1,58,37.54a4.22,4.22,0,0,1-4.3-4c-.62-8.93-5.18-15.15-13.17-18-7.39-2.63-14.29-1.13-20,4.33-5.83,5.63-7.54,12.68-5,20.38,2.46,7.32,7.78,11.73,15.8,13.11a15.56,15.56,0,0,0,2.27.18,3.12,3.12,0,0,0,2.41-.85,2.63,2.63,0,0,0,.74-2c0-1.08-.57-2.43-2.81-2.74L33,47.86a17.49,17.49,0,0,1-3.65-.71A14,14,0,0,1,20.39,31,14.16,14.16,0,0,1,35.57,20c6.72.55,12.14,6.31,12.61,13.38a13.64,13.64,0,0,0,.69,3c.14.44.28.89.39,1.33q0,.18.06.27c-2.89,5.76-2.31,10.11,2,14.27A11.15,11.15,0,0,0,47.63,59,11.31,11.31,0,0,0,49,66.09H45.79c-3.68,0-7.48,0-11.21-.06A29.52,29.52,0,0,1,6.76,44.51,30.74,30.74,0,0,1,10,19.65,29.28,29.28,0,0,1,30,6.05a30,30,0,0,1,5.26-.47C43.85,5.58,51.17,9.62,57.11,17.63Zm1.34,37.56H74.39a5.44,5.44,0,0,1,4,9.2A5.15,5.15,0,0,1,74.69,66c-5.58.11-11.14.11-16.52,0a5.12,5.12,0,0,1-3.64-1.64,5.42,5.42,0,0,1,3.92-9.18Z"/>
                </g>
            </g>
        </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }
  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    .Logo__Mobile{
      display: block;
    }
  `}
`;